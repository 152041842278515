<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cierres Operativos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Cierres Operativos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <!-- <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_export_solInte"
                        @click="limpiarExport()"
                        v-if="
                          $store.getters.can(
                            'funcionariofrontera.csSolicitudInterna.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button> -->
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.operativoCierre.create'
                          )
                        "
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex(), getGrafica()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="limpiarFiltro()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row justify-content-center">
                  <div class="form-group col-md-3">
                    <label>Sitio</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.sitio"
                      @change="getIndex(), getGrafica()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Usuario</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.user_creacion"
                      @change="getIndex(), getGrafica()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Usuario Cierre</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.user_cierre"
                      @change="getIndex(), getGrafica()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha"
                      @input="getIndex(), getGrafica()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha Cierre</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_cierre"
                      @input="getIndex(), getGrafica()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex(), getGrafica()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                  style="font-size: 0.9em"
                >
                  <thead class="thead bg-dark">
                    <tr class="text-center">
                      <th>#</th>
                      <th>Usuario</th>
                      <th>Usuario Cierre</th>
                      <th>Fecha Solicitud</th>
                      <th>Fecha Cierre</th>
                      <th>Sitio</th>
                      <th>Estado</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(cierre, index) in cierresOperativos.data"
                      :key="index"
                    >
                      <td class="text-center">{{ cierre.id }}</td>
                      <td class="text-center">{{ cierre.user.name }}</td>
                      <td class="text-center" v-if="cierre.cierre_user">
                        {{ cierre.cierre_user.name }}
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge bg-danger"> Sin Cierre</span>
                      </td>
                      <td class="text-center">{{ cierre.fecha_solicitud }}</td>
                      <td class="text-center" v-if="cierre.fecha_cierre_campo">
                        {{ cierre.fecha_cierre_campo }}
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge bg-danger"> Sin Cierre</span>
                      </td>
                      <td class="text-center">{{ cierre.sitio.nombre }}</td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            cierre.estado == 1 ? 'bg-warning' : 'bg-success'
                          "
                          >{{ cierre.nEstado }}</span
                        >
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm bg-navy"
                          title="Editar Cierre"
                          @click="editar(cierre)"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.operativoCierre.edit'
                            )
                          "
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="cierresOperativos.total">
                  <p>
                    Mostrando del <b>{{ cierresOperativos.from }}</b> al
                    <b>{{ cierresOperativos.to }}</b> de un total:
                    <b>{{ cierresOperativos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="cierresOperativos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
            <!-- Gráfica -->
            <div class="card" :class="cargando_div ? 'overlay-wrapper' : ''">
              <div v-if="cargando_div" class="overlay">
                <i class="fas fa-5x fa-sync-alt fa-spin"></i>
                <div class="text-lg pt-2">Cargando...</div>
              </div>
              <div>
                <highcharts :options="chartOptions"></highcharts>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import { Chart } from "highcharts-vue";

export default {
  name: "CierreOperativoIndex",

  components: {
    pagination,
    Loading,
    highcharts: Chart,
  },

  data() {
    return {
      cargando: false,
      cargando_div: false,
      filtros: {
        sitio: null,
        fecha: null,
        fecha_cierre: null,
        user_cierre: null,
        user_creacion: null,
        estado: null,
      },
      cierresOperativos: {},
      listasForms: {
        estados: [],
      },
      chartOptions: {
        xAxis: {
          categories: [],
        },
        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Cantidad De Cierres",
          },
        },
        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "CANTIDAD DE CIERRES POR SITIOS",
          align: "center",
        },
        series: [
          {
            type: "column",
            name: ["Pendientes"],
            data: [],
            color: "#ffbb33",
          },
          {
            type: "column",
            name: "Definitivas",
            data: [],
            color: "#0099CC",
          },
          {
            type: "column",
            name: "Abiertas",
            data: [],
            color: "#00C851",
          },
          {
            type: "column",
            name: "Cerradas",
            data: [],
            color: "#143358",
          },
        ],
        credits: {
          enabled: false,
        },
        hoverBorderWidth: 10,
      },

      animation: {
        animateRotate: false,
        animateScale: true,
      },
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/cierresOperativos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cierresOperativos = response.data;
          this.cargando = false;
        });
      this.cargando = false;
    },

    async getGrafica() {
      let me = this;
      this.cargando_div = true;
      await axios
        .get("/api/hidrocarburos/cierresOperativos/graficas", {
          params: me.filtros,
        })
        .then(async (response) => {
          this.cargando_div = false;
          let respuesta = response.data;
          me.chartOptions.xAxis.categories = [];
          me.chartOptions.series[0].data = [];
          me.chartOptions.series[1].data = [];
          me.chartOptions.series[2].data = [];
          me.chartOptions.series[3].data = [];

          Object.entries(respuesta.groupSitio).forEach(([key, sit]) => {
            if (
              !me.filtros.sitio_id ||
              (me.filtros.sitio_id && me.filtros.sitio_id == key)
            ) {
              if (sit.Sitio) {
                this.chartOptions.xAxis.categories.push(sit.Sitio);
              }

              if (sit.cantPend) {
                this.chartOptions.series[0].data.push(sit.cantPend);
              }

              if (sit.cantDef) {
                this.chartOptions.series[1].data.push(sit.cantDef);
              }

              if (sit.cantSinCi) {
                this.chartOptions.series[2].data.push(sit.cantSinCi);
              }

              if (sit.cantConCi) {
                this.chartOptions.series[3].data.push(sit.cantConCi);
              }
            }
          });
        });
      this.cargando_div = false;
    },

    async getEstados() {
      await axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/CierresOperativosForm",
        params: {
          accion: "Crear",
          id: 0,
        },
      });
    },

    editar(cierre) {
      return this.$router.push({
        name: "/Hidrocarburos/CierresOperativosForm",
        params: {
          accion: "Editar",
          data_edit: cierre,
        },
      });
    },

    limpiarFiltro() {
      this.filtros = {
        sitio: null,
        fecha: null,
        fecha_cierre: null,
        user_cierre: null,
        user_creacion: null,
        estado: null,
      };
    },
  },

  async beforeMount() {
    await this.getEstados();
  },

  async mounted() {
    await this.getIndex();
    await this.getGrafica();
  },
};
</script>
